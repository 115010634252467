
import React from "react";
import AppData from '../../../appData'
import { RestServer } from './../../../REST/axios'
import { formData, formDataInitializeVOHVOL } from "./detailscreen";
import configJson from "../../../config.json"
// import { ErrorIF } from '../../../appTypes'
// import Blockdiagram from '../../../Components/blockDiagram/blockDiagram'


async function getBlockFields(component: string) {
    let result: any
    //get Field info from server
    AppData.api.blockDiagramFields.bodyParams = {
        user_id: AppData.User.data._id, device_id: AppData.currentConfig.device_id,
        block_diagram_id: AppData.blockDiagramData[0]._id, component_name: component
    }
    try {
        result = await AppData.localServer.sendCommand(AppData.api.blockDiagramFields, `Getting field for component failed.`, 1)
        return result
    } catch {
        return {}
    }
}
async function changeRegister(id: string, value: string) {
    const newVal = (value == "true") ? "1" : (value == "false") ? "0" : value
    AppData.api.modifyRegisterState.bodyParams = { field_id: id, token_value: newVal, source_screen: "BlockDiagramView" }
    await AppData.localServer.sendCommand(AppData.api.modifyRegisterState, `setting field ${id} to ${value} failed`, 1)
    const _result = await AppData.localServer.sendCommand(AppData.api.getAllRegisterStates, "Get all register states", 1)
    AppData.register.data = _result
    const result = getBlockFields(AppData.componentName)
    return result
}

async function getBlockFunctions(component: string[], server: string) {
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    let result: any
    //get function info from server
    AppData.api.componentFunctionsSearch.bodyParams = {
        user_id: AppData.User.data._id, device_id: AppData.currentConfig.device_id,
        block_diagram_id: AppData.blockDiagramData[0]._id, component_name: component
    }
    try {
        result = await _sourceServer.sendCommand(AppData.api.componentFunctionsSearch, `Getting field for component failed.`, 1)
        return result
    } catch {
        return {}
    }
}
async function changeFunctionState(id: string, value: any, label: string, setalert: any, server: string) {
    //Onchange function to change token value for functional view
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    console.log('vddval', AppData.vddVal)
    let res_validation = ''
    let function_list = []
    if ((label === 'CLK_P') || (label === 'CLK_N')) {
        if ((value === false)) {
            value = 'False'
        }
        else if ((value === true)) {
            value = 'True'
        }
    }
    if (label === 'VDDO') {
        if (typeof (value) == 'string') {
            let val = parseFloat(value)
            AppData.vddVal = val
        }
        //Reset functions when VDDO is selected, do vdd and skew rate calculation
        function_list.push(AppData.refFunction, AppData.slewFunctionCLKN, AppData.slewFunctionCLKP, AppData.vohid, AppData.volid)
        AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName }
        await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
        console.log(value, AppData.vddVal, AppData.vohid, AppData.volid, AppData.refId, AppData.refFunction)
        AppData.api.vddCalculation.bodyParams = { vdd: AppData.vddVal, voh: AppData.vohid, vol: AppData.volid, refVal: AppData.refId, refFunction: AppData.refFunction }
        await AppData.localServer.sendCommand(AppData.api.vddCalculation, `fetching value for VOH,VOL failed`)
        AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVal, function: AppData.slewFunctionCLKP }
        await AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
        AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVal, function: AppData.slewFunctionCLKN }
        await AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)

    }
    if (label === 'Reference Voltage(VoH/VoL)') {
        AppData.refId = value
        function_list.push(AppData.vohid, AppData.volid)
        AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName }
        await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
        console.log(AppData.vddVal, AppData.vohid, AppData.volid, AppData.refId, AppData.refFunction)
        AppData.api.vddCalculation.bodyParams = { vdd: AppData.vddVal, voh: AppData.vohid, vol: AppData.volid, refVal: AppData.refId, refFunction: AppData.refFunction }
        await AppData.localServer.sendCommand(AppData.api.vddCalculation, `fetching value for VOH,VOL failed`)

    }
    if (value === 'FlexSwing') {
        AppData.api.vddCalculation.bodyParams = { vdd: AppData.vddVal, voh: AppData.vohid, vol: AppData.volid, refVal: AppData.refId, refFunction: AppData.refFunction }
        await AppData.localServer.sendCommand(AppData.api.vddCalculation, `fetching value for VOH,VOL failed`)
    }
    if (value === 'Single Ended (LVCMOS)') {
        //Reset functions when SE is selected, do vdd and skew rate calculation
        function_list.push(AppData.refFunction, AppData.slewFunctionCLKN, AppData.slewFunctionCLKP, AppData.vohid, AppData.volid, AppData.checkClkNId, AppData.checkClkPId, AppData.radioClkNId, AppData.radioClkPId)
        AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName }
        await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
        AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVal, function: AppData.slewFunctionCLKP }
        await AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
        AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVal, function: AppData.slewFunctionCLKN }
        await AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
        AppData.api.vddCalculation.bodyParams = { vdd: AppData.vddVal, voh: AppData.vohid, vol: AppData.volid, refVal: AppData.refId, refFunction: AppData.refFunction }
        await AppData.localServer.sendCommand(AppData.api.vddCalculation, `fetching value for VOH,VOL failed`)
    }
    if (value === 'Differential') {
        function_list.push(AppData.vohid, AppData.volid, AppData.refFunction, AppData.signalingId)
        AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName }
        await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
    }
    if (label === 'Frequency(MHz)' && value === 'Invalid') {
        console.log(value)
    }
    else {
        AppData.api.componentFunctionsProcess.bodyParams = {
            device_id: AppData.currentConfig.device_id, function_name: id, parameter_name: label, token_name: value,
            block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName
        }
        const res = await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess, `setting function ${value} to ${label} failed`, 1)

        if ((label === 'Frequency(MHz)') && (res.status.integer_solution === 'NO') && (res.status.fractional_solution === 'NO')) {
            AppData.alert = 'No Solution Found'
            // toggle()
        }
        else {
            if (label === 'Frequency(MHz)')
            {
                let checkValue = (value/(10**6)).toString();
                AppData.disableDiff = true
                if (AppData.deviceFrequenciesDataDiff.length != 0 && AppData.deviceFrequenciesDataDiff.includes(checkValue)) {
                    if(configJson.DISABLE_OUTPUT_TYPE == 1 && AppData.connected == false){
                        AppData.disableDiff = false
                    }
                    if(!AppData.deviceFrequenciesDataSE.includes(checkValue)){
                        AppData.disableLVCMOS = true
                         //Reset functions when SE is selected, do vdd and skew rate calculation
                        var dict = {} as any;
                        dict['OUT0Frequency'] = 0
                        dict['OUT1Frequency'] = 1
                        dict['OUT2Frequency'] = 2
                        dict['OUT3Frequency'] = 3                        
                        var value = dict[id];
                        function_list.push(AppData.vohid, AppData.volid, AppData.refFunction, AppData.signalingId)
                        AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName }
                        await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
                        await processTypeFunction(value,'Differential')
                    }
                }
                
                if (AppData.deviceFrequenciesDataSE.length != 0 && AppData.deviceFrequenciesDataSE.includes(checkValue)) {
                    if(configJson.DISABLE_OUTPUT_TYPE == 1 && AppData.connected == false){
                        AppData.disableLVCMOS = false
                    }
                    if(!AppData.deviceFrequenciesDataDiff.includes(checkValue)){
                        AppData.disableDiff = true
                        var dict = {} as any;
                        dict['OUT0Frequency'] = 0
                        dict['OUT1Frequency'] = 1
                        dict['OUT2Frequency'] = 2
                        dict['OUT3Frequency'] = 3 
                        function_list.push(AppData.refFunction, AppData.slewFunctionCLKN, AppData.slewFunctionCLKP, AppData.vohid, AppData.volid, AppData.checkClkNId, AppData.checkClkPId, AppData.radioClkNId, AppData.radioClkPId)
                        AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: AppData.componentName }
                        await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
                        AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVal, function: AppData.slewFunctionCLKP }
                        await AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
                        AppData.api.slewRateCalculation.bodyParams = { vddio: AppData.vddVal, function: AppData.slewFunctionCLKN }
                        await AppData.localServer.sendCommand(AppData.api.slewRateCalculation, `fetching slewrate value failed`, 1)
                        AppData.api.vddCalculation.bodyParams = { vdd: AppData.vddVal, voh: AppData.vohid, vol: AppData.volid, refVal: AppData.refId, refFunction: AppData.refFunction }
                        await AppData.localServer.sendCommand(AppData.api.vddCalculation, `fetching value for VOH,VOL failed`)                      
                        await processTypeFunction(dict[id],'Single Ended (LVCMOS)')
                    }
                }
            }
            AppData.alert = ''
            var list = [AppData.componentName]
            const result = await getBlockFunctions(list, server)

            AppData.api.historySearch.bodyParams = { 'udc_uuid': AppData.currentConfig.udc_uuid }
            const historyData = _sourceServer.sendCommand(AppData.api.historySearch, `Getting config data from local server failed.`, 1)
            historyData.then((res: any) => { AppData.history.data.local = res })
            {
                (server == 'local') ?
                    AppData.userConfig.data.local = await _sourceServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1) :
                    AppData.userConfig.data.remote = await _sourceServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1)
            }

            return result
        }
    }
}

async function configurationSearch(setConfigData: any, setRemoteData: any, setRecentData: any) {
    try {
        var current = await AppData.localServer.sendCommand(AppData.api.currentConfig, `Getting current configuration failed`)
        AppData.api.deviceSearchWithId.bodyParams = { _id: current.device_id }
        var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
        current['platform'] = currentDevice[0].platform
        current['variant'] = currentDevice[0].variant
        AppData.currentConfig = current;
    }
    catch {
        
    }
    await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        var recentData = sortedData.slice(0, 2);
        AppData.userConfig.data.local = sortedData
        setConfigData(AppData.userConfig.data.local)
        AppData.userConfig.data.recent = recentData;
        setRecentData(AppData.userConfig.data.recent)
    })
    if (navigator.onLine == false) { AppData.userConfig.data.remote = [] }
    if (navigator.onLine == true) {
        await AppData.remoteServer.sendCommand(AppData.api.configSearch, `Getting config data from remote server failed.`, 1).then((res: any) => {
            let actualData1 = res;
            let modified_date: any[] = [];
            actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })

            let a = []
            let sortedData = []
            for (let i in modified_date) {
                a.push([i, modified_date[i]])
            }
            a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
            for (let i in a) {
                sortedData.push(actualData1[a[i][0]])
            }
            AppData.userConfig.data.remote = sortedData
            setRemoteData(AppData.userConfig.data.remote)
        })
    }
}

async function freezeConfig(setFreeze: any, setConfigData: any, setRemoteData: any, setRecentData: any, setMessage?: any) {
    //Get sequence number from remote server using /get_sequence_number API
    //Call Free API on local server passing the sequence number got from remote server
    if (AppData.freezeData[1] == 'B') {
        AppData.api.getSequenceNumber.bodyParams = { "platform": AppData.currentConfig.platform, "variant": AppData.currentConfig.variant, "user_id": AppData.User.data._id, "config_uuid": AppData.currentConfig.udc_uuid, "category": "B" }
        var sequenceNumber = await AppData.remoteServer.sendCommand(AppData.api.getSequenceNumber, `Getting Sequence Number failed`, 1)
        AppData.sequenceNum = sequenceNumber
    }
    else {
        AppData.sequenceNum.sequence = '00000'
    }
    AppData.api.freezeConfig.bodyParams = { "sequence": AppData.sequenceNum.sequence, "category": AppData.freezeData[1], "frequency_stability": AppData.freezeData[2], "packaging": AppData.freezeData[3], "temperature_range": AppData.freezeData[0] }
    await AppData.localServer.sendCommand(AppData.api.freezeConfig, `Freezing the config failed`, 1)
    var current = await AppData.localServer.sendCommand(AppData.api.currentConfig, `Getting current configuration failed`, 1)
    AppData.api.deviceSearchWithId.bodyParams = { _id: current.device_id }
    var currentDevice = await AppData.localServer.sendCommand(AppData.api.deviceSearchWithId, `Getting current Device failed`, 1)
    current['platform'] = currentDevice[0].platform
    current['variant'] = currentDevice[0].variant
    AppData.currentConfig = current;
    await AppData.localServer.sendCommand(AppData.api.configSearch, `Getting config data from local server failed.`, 1).then((res: any) => {
        let actualData1 = res;
        let modified_date: any[] = [];
        actualData1.forEach((value: any) => { return modified_date.push(value['modified_date'].replace('T', ' ').split('.')[0]) })
        let a = []
        let sortedData = []
        for (let i in modified_date) {
            a.push([i, modified_date[i]])
        }
        a.sort(function (i, j) { return i[1] - j[1]; }).reverse();
        for (let i in a) {
            sortedData.push(actualData1[a[i][0]])
        }
        AppData.userConfig.data.local = sortedData;
        var recentData = sortedData.slice(0, 2);
        AppData.userConfig.data.recent = recentData;
        setRecentData(recentData);
        setConfigData(AppData.userConfig.data.local, AppData.userConfig.data.recent)
    })
    if ((AppData.currentConfig.frozen) || (AppData.currentConfig.custom_part_number)) {
        setFreeze(false)
        AppData.componentName = ""
        AppData.label = ""
        AppData.functionFormData = {
            "param": [],
            "commonfunctions": [],
            "delay": [],
            "type": [],
            "differential": [],
            "single": [],
            "op": [],
            "singlecommon": [],
            "singlevoh": [],
            "diffvoh": [],
            "slew": [],
            "diffref": [],
            "freq": [],
            "vdd": []
        }
        setMessage(true, `${AppData.currentConfig.name} Configuration was frozen`)
        AppData.freezeData = []
    }
    formDataInitializeVOHVOL()
    formData()
}
async function getDeviceProperties() {
    const _sourceServer = (AppData.server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.devicePropertiesSearch.bodyParams = { "device_id": AppData.currentConfig.device_id }
    var device_properties = await _sourceServer.sendCommand(AppData.api.devicePropertiesSearch, `Getting device properties failed`, 1)
    AppData.devicePropertyData = device_properties
}

async function getDevicePropertiesByName(server: string, name: string) {
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.devicePropertiesSearchByName.bodyParams = { "device_id": AppData.currentConfig.device_id, "name": name }
    var device_properties = await _sourceServer.sendCommand(AppData.api.devicePropertiesSearchByName, `Getting device properties failed`, 1)
    return device_properties
}

async function historySearch() {
    const _sourceServer = (AppData.server == 'local') ? AppData.localServer : AppData.remoteServer
    const historyData = await _sourceServer.sendCommand(AppData.api.historySearch, `Getting config data from local server failed.`, 1)
    AppData.history.data.local = historyData
}
async function getPinDiagramData(currentRow: any, server: string, diagramName: string) {
    //Fetch pinDiagram based on selected interface
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.blockDiagramSearch.bodyParams = { device_id: currentRow['device_id'], name: currentRow['platform'].toUpperCase() + " " + currentRow['variant'].toUpperCase() + " " + diagramName }
    const result = await _sourceServer.sendCommand(AppData.api.blockDiagramSearch, `Getting Block diagram failed`, 1)
    AppData.pinDiagramData = result
    console.log('pinn', AppData.pinDiagramData)
}

async function getRealTimePinDiagramData( server: string, diagramName: string,device_id: string,platform: string,variant: string) {
    //Fetch pinDiagram based on selected interface
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.blockDiagramSearch.bodyParams = { device_id: device_id, name: platform.toUpperCase() + " " + variant.toUpperCase() + " " + diagramName }
    const result = await _sourceServer.sendCommand(AppData.api.blockDiagramSearch, `Getting Block diagram failed`, 1)
    AppData.pinDiagramData = result
    console.log('pinn', AppData.pinDiagramData)
}

async function getFunctionState(function_list: any, server: string, _id: any) {
    //Get function state of functions
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.getFunctionState.bodyParams = {
        user_id: AppData.User.data._id, device_id: AppData.currentConfig.device_id,
        block_diagram_id: _id, function_name_list: function_list
    }
    try {
        const result = await _sourceServer.sendCommand(AppData.api.getFunctionState, `Getting field for component failed.`, 1)
        if ((result[0].name = "I2C_SPI_NONE") && (result[0].parameters[0].param_name == 'Mode')) {
            AppData.GPIOstate = result[0].function_state
        }
        return result
    } catch {
        return {}
    }
}
async function getPinFunction(component: string[], server: string) {
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    let result: any
    //get function info from server
    AppData.api.componentFunctionsSearch.bodyParams = {
        user_id: AppData.User.data._id, device_id: AppData.currentConfig.device_id,
        block_diagram_id: AppData.pinDiagramData[0]._id, component_name: component
    }
    try {
        result = await _sourceServer.sendCommand(AppData.api.componentFunctionsSearch, `Getting field for component failed.`, 1)
        return result
    } catch {
        return {}
    }
}
async function processGPIO(compName: string, functionName: string, paramName: string, val: string) {
    AppData.api.componentFunctionsProcess.bodyParams = {
        device_id: AppData.currentConfig.device_id, function_name: functionName, parameter_name: paramName, token_name: val,
        block_diagram_id: AppData.pinDiagramData[0]._id, component_name: compName
    }
    try {
        const res = await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess, `setting function ${val} to ${paramName} failed`, 1)
    }
    catch {
        if (functionName === "Address") { return 'Enter a valid address' }
        else {
            return 'OE_ALL is selected '
        }
    }
}
async function resetFunctions(function_list: string[]) {
    AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.pinDiagramData[0]._id, component_name: AppData.pinComp }
    await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
    console.log('done')
}
async function functionSearch(functionName: string, server: string) {
    const _sourceServer = (server == 'local') ? AppData.localServer : AppData.remoteServer
    AppData.api.functionSearch.bodyParams = { functionName: functionName }
    const result = await _sourceServer.sendCommand(AppData.api.functionSearch, `Getting funtion failed`, 1)
    return result
}
async function realizeFrequencies(ppmValue: string) {
    AppData.api.componentFunctionsProcess.bodyParams = { "block_diagram_id": AppData.blockDiagramData[0]._id, "component_name": AppData.frequencies_data[0]["component_name"], "device_id": AppData.currentConfig.device_id, "function_name": "PPM", "parameter_name": "PPM", "token_name": ppmValue }
    const ppmRes = await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess, `Component process failed`, 1)
    AppData.api.realizeFrequency.bodyParams = { 'block_diagram_id': AppData.blockDiagramData[0]._id, "device_id": AppData.currentConfig.device_id, "frequencies_data": AppData.freqData }
    const result = await AppData.localServer.sendCommand(AppData.api.realizeFrequency, `Realizing frequencies failed`, 1)
    if ((result["status"]["integer_solution"] === 'NO') && (result['status']['fractional_solution'] === "NO")) {
        return 'No Solution Found'
    }
    else { return '' }
}
async function processFunction(index: any,token_name: any) {
    var key = index.toString()
    index += 1
    index = index.toString()
    AppData.api.componentFunctionsProcess.bodyParams = { "block_diagram_id": AppData.blockDiagramData[0]._id, "component_name": "driver" + index, "device_id": AppData.currentConfig.device_id, "function_name": "OUT" + key + "Mode", "parameter_name": "Mode", "token_name": token_name }
    const Res = await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess, `Component process failed`, 1)

}

async function processTypeFunction(index: any,token_name: any) {
    var key = index.toString()
    index += 1
    index = index.toString()
    AppData.api.componentFunctionsProcess.bodyParams = { "block_diagram_id": AppData.blockDiagramData[0]._id, "component_name": "driver" + index, "device_id": AppData.currentConfig.device_id, "function_name": "OUT" + key + "Type","parameter_name": "Type", "token_name": token_name }
    const Res = await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess, `Component process failed`, 1)
    return Res
}

async function percentSpread (mode: any,device: any) {
    AppData.api.percentSpread.bodyParams = {'device':device ,"function_name": "Percent_spread","mode":mode}
    const res = await AppData.localServer.sendCommand(AppData.api.percentSpread,`Fetching details failed`)
    return res
}
async function percentReset(function_list: any) {
    AppData.api.resetFunctions.bodyParams = { device_id: AppData.currentConfig.device_id, function_list: function_list, block_diagram_id: AppData.blockDiagramData[0]._id, component_name: 'driver1'}
    await AppData.localServer.sendCommand(AppData.api.resetFunctions, `reseting functions failed`, 1)
}
async function processComponentFUnctions(functionName: string,token: string,parameter: string,setSSC: any){
    AppData.api.componentFunctionsProcess.bodyParams = { "block_diagram_id": AppData.blockDiagramData[0]._id, "component_name": "driver1", "device_id": AppData.currentConfig.device_id, "function_name":functionName, "parameter_name": parameter, "token_name": token }
    const Res = await AppData.localServer.sendCommand(AppData.api.componentFunctionsProcess, `Component process failed`, 1)
    setSSC(false)
}
async function getRegisterState(regAddress: any){
    const _sourceServer = (AppData.server == 'local') ? AppData.localServer : AppData.remoteServer
    var list = ['']
    AppData.api.registerSearchWithAddress.bodyParams = {"device_id": AppData.currentConfig.device_id,"address":regAddress}
    const result = await _sourceServer.sendCommand(AppData.api.registerSearchWithAddress,`Searching register with address failed`)
    list[0] = result[0]._id 
    AppData.api.getRegisterStates.bodyParams = { "regList": list}
    const res = await _sourceServer.sendCommand(AppData.api.getRegisterStates,`Getting register states failed`)
    console.log('res',res)
    return res[0].register_state
}
async function writeToDevice(regList: any){
    AppData.api.writeDriver.bodyParams = {"regData":regList,"protocol":AppData.protocol,"slave_address":AppData.slave_address}
    await AppData.deviceServer.sendCommand(AppData.api.writeDriver,`Writing to driver failed`)
}
export { writeToDevice,getRegisterState,percentReset,processComponentFUnctions,percentSpread,processFunction, processTypeFunction,realizeFrequencies, functionSearch, resetFunctions, getPinFunction, processGPIO, getFunctionState, getPinDiagramData, getBlockFields, changeRegister, changeFunctionState, getBlockFunctions, configurationSearch, freezeConfig, getDeviceProperties, historySearch, getDevicePropertiesByName,getRealTimePinDiagramData }

