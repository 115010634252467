import React, { useRef, useState } from "react";
import AppData from "../../../appData";
import { Button } from "../../../components/button/button";
import Dropdown from "../../../components/dropDown/dropDown";
import FlexBox from "../../../components/flexBox/flexBox";
import InputBoolean from "../../../components/inputBoolean/inputBoolean";
import InputString from "../../../components/inputString/inputString";
import RadioButton from "../../../components/radioButton/radioButton";
import Icons from "../../../icons/icons";
import Checkbox from "../../../components/checkBox/checkBox";
import SelectButton from "../../../components/selectButton/selectButton";
import Tooltip from "../../../components/toolTip/toolTip";
import configJson from "../../../config.json"

type ElemType = "check" | "select" | "text" | "button" | "radio"|"selectbutton";
interface RadIf extends ElemIF {
    items: { name: string, key: string }[],
    initial: string,
    tooltipposition: 'right' | 'left' | 'top' | 'bottom',
    help: string
}
interface DropIF extends ElemIF {
    contentItems:any
    scrollHeight?: string,
    tooltipposition: 'right'|'left'|'top'|'bottom',
    help: string,
    shape: string,
    search: boolean
}
interface CheckIF extends ElemIF {
    initial:boolean
    items: { name: string, key: string }[],
    labelvalues:string
}
interface TextIF extends ElemIF {
    errorMessage?: string
    error: boolean
}
interface SelectIF extends ElemIF {
    values: any
    initial: string
}
export interface ElemIF {
    type: ElemType;
    id: string;
    name: string;
    label: string;
    disable: any
    placeHolder?: string;
    fill?: string
    stroke?: string
    content: string;
    regExp?: string;
    tooltipPosition: 'right'|'left'|'top'|'bottom',
    tooltip: string
}
interface propsIF {
    header: string
    initFormData: ElemIF[];
    initfreqData: ElemIF[];
    initdiffdata: ElemIF[];
    initsingledata: ElemIF[];
    initParamData: ElemIF[];
    initopdata: ElemIF[]
    initsinglecommonData: ElemIF[];
    inittypeData: ElemIF[];
    initdelayData: ElemIF[];
    onItemChange: (id: string, value: string, label: string) => void;
    onBackclick?:()=>void
    initsinglevohData: ElemIF[]
    initdiffvohData: ElemIF[]
    initSlewData: ElemIF[]
    initdiffref: ElemIF[];
}
function FunctionalForm({initSlewData,initfreqData, initdiffvohData,initdiffref,initsinglevohData,header,initdelayData,onBackclick, initFormData,inittypeData, initParamData, initdiffdata, initsingledata, initopdata, initsinglecommonData, onItemChange }: propsIF) {
    const formData = useRef(initFormData.map(item => { return { id: item.id, value: "" } }))
    const getElement = (elemData: ElemIF) => {
        const assignVal = (value: any) => {
            //Onchange of each element
            console.log('item change',elemData.id, value, elemData.label)
            onItemChange(elemData.id, value, elemData.label)
        }
        switch (elemData.type) {
            case "text":
                let thisTextdata = elemData as TextIF
                if(thisTextdata.label == 'Frequency(MHz)')
                {
                    AppData.disableDiff = thisTextdata.disable
                    AppData.disableLVCMOS = thisTextdata.disable
                    if(configJson.DISABLE_OUTPUT_TYPE == 1 && AppData.connected == true){
                        AppData.disableLVCMOS = true
                        AppData.disableDiff  = true
                    }
                    if (AppData.deviceFrequenciesDataDiff.length != 0 && thisTextdata.content != '' && !AppData.deviceFrequenciesDataDiff.includes(thisTextdata.content)) {
                        AppData.disableDiff = true
                    }
                    
                    if (AppData.deviceFrequenciesDataSE.length != 0 && thisTextdata.content != '' && !AppData.deviceFrequenciesDataSE.includes(thisTextdata.content)) {
                        AppData.disableLVCMOS = true
                    }
                }
                return (
                    <InputString Error={true} disabled={thisTextdata.disable} spacing='frequency' iconcolor="white" shape='mode'icon={false} Enter={true} IconSize="20px"   placeholder={thisTextdata.placeHolder} regex={undefined} fill='functional' stroke='none' clickIcon={assignVal} fonts={''} content={thisTextdata.content} errorMessage={thisTextdata.errorMessage}></InputString>
                )
                break;
            case "select":
                let thisSelectdata = elemData as DropIF
                return (
                    <Dropdown  disabled={thisSelectdata.disable} iconcolor="dropdown" current={thisSelectdata.content} spacing="normal" shape={thisSelectdata.shape} fill='popupform1' select={'single'} InputSearch={thisSelectdata.search} effects={'dropShadow'} contentItems={thisSelectdata.contentItems} stroke={'none'}  onClick={assignVal}></Dropdown>
                )
                break;
            case "button":
                return (
                    <Button fill='signin' color='white' shape={elemData.name}>{elemData.content}</Button>
                )
            case "radio":
                let thisRadiodata = elemData as RadIf
                return (
                    <RadioButton radios={thisRadiodata.items} display={"column"} onClick={assignVal} initialState={thisRadiodata.initial} disabled={thisRadiodata.disable}/>
                )
            case "check":
                let thisCheckdata = elemData as CheckIF
                return (
                    <Checkbox values={thisCheckdata.items} onClick={assignVal} initialState={thisCheckdata.initial}  disabled={thisCheckdata.disable} />
                ) 
            case "selectbutton":
                let thisSelectButtondata = elemData as SelectIF
                return(
                    <SelectButton initial={thisSelectButtondata.initial}  values={thisSelectButtondata.values} onClick={assignVal} disabled ={[AppData.disableDiff,AppData.disableLVCMOS]} shape="functional" stroke="functional" fill="functional" select={"single"} orientation={"horizontal"}/>
                )
        }
    }
    const allElements = (elemData: ElemIF) => {
        //Map each element along with the label
        return (
        <Tooltip shape="functionalForm" fill="grey" spacing="normal" font="normal" message={elemData.tooltip} location="top"  X={"0px"} Y={"-100px"}>
            <div >
                    <p style={{marginBlockEnd:'7px',marginBlockStart:"0px"}}>{((elemData.type==='radio')&&((elemData.label==='CLK_P')||(elemData.label==='CLK_N')))?"":elemData.label}</p>
                    {getElement(elemData)}
            </div>
        </Tooltip>
        )
    };
    return (
        <div style={{padding:'0px',margin:'0px',overflowY:(header!=='')? 'scroll':'hidden'}}>
        <FlexBox shape="hug" layout="vertical-top-left" spacing="functional" >
            <FlexBox shape="formHeader" spacing="mode" layout="vertical-top-left">
                {(header!='')&&<div style={{margin:'0px',padding:'0px',display:'flex',alignItems:'center',justifyContent:'center',gap:'14px',cursor:"pointer",color:"#8E8D8A"}} onClick={onBackclick}><Icons iconName={"arrow-left"} size={"20px"}/> Back</div>}
                <div style={{fontSize:'26px',color:'#231F20'}}>{header}</div>
            </FlexBox>
            <FlexBox shape="hug" spacing="type" layout="vertical-top-left">
                <FlexBox layout="horizontal-top-left" shape="hug" spacing="mode">
                    {/* mode and vddio */}
                    {initParamData.map(item =>(allElements(item) ))}
                </FlexBox>
                <FlexBox layout="horizontal-top-left" shape="hug" spacing="mode">
                    {/* frequency ppm*/}
                    {initfreqData.map(item =>(allElements(item) ))}
                    </FlexBox>
                <FlexBox layout="vertical-top-left" shape="hug" spacing="mode">
                    {/* frequency delay Hi-Z*/}
                    <FlexBox shape="hug" spacing="mode" layout="vertical-top-left" >
                        {initFormData.map(item => allElements(item))}
                    </FlexBox>
                    <FlexBox layout="horizontal-top-left" shape="hug" spacing="mode">
                    {/* delay clkp clkn*/}
                    {initdelayData.map(item =>(allElements(item) ))}
                    </FlexBox>
                </FlexBox>
                <FlexBox shape="hug" spacing="none">
                     {/* type */}
                        {inittypeData.map(item => allElements(item))}
                </FlexBox>
                <FlexBox shape="hug" spacing="type" layout="vertical-top-left" >
                    {(AppData.type == 'Differential') && <FlexBox layout="vertical-top-left" shape="hug" spacing="type">
                        {initdiffdata.map(item => allElements(item))}
                            {(AppData.signal=="FlexSwing")&&<FlexBox spacing="mode" layout="vertical-top-left" shape="hug">
                                <FlexBox spacing="mode" layout="vertical-top-left" shape="hug">
                                    {initdiffref.map(item => allElements(item))}
                                </FlexBox>
                                <FlexBox spacing="mode" layout="horizontal-top-left" shape="hug">
                                    {initdiffvohData.map(item => allElements(item))}
                                </FlexBox>
                            </FlexBox>}
                    </FlexBox>}
                    {(AppData.type  == 'Single Ended (LVCMOS)') && <FlexBox layout="vertical-top-left" shape="hug" spacing="type">
                        <FlexBox shape="hug" layout="horizontal-top-left" spacing="outp">
                            {initopdata.map(item => allElements(item))}
                        </FlexBox>
                        <FlexBox layout="horizontal-top-left" shape="hug" spacing="SE">
                            {initsingledata.map(item => allElements(item))}
                        </FlexBox>
                        <FlexBox spacing="mode" layout="horizontal-top-left" shape="hug">
                        {initSlewData.map(item => allElements(item))}
                        </FlexBox>
                        {initsinglecommonData.map(item => allElements(item))}
                        <FlexBox spacing="mode" layout="horizontal-top-left" shape="hug">
                        {initsinglevohData.map(item => allElements(item))}</FlexBox>
                    </FlexBox>}
                </FlexBox>
            </FlexBox>
        </FlexBox>
    </div>
    )
}
export default FunctionalForm